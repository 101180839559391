import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Box } from "rebass";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import useArtist from "../../../hooks/artist";
import Dropdown from "../../../components/_layout/dropdown";

const DropdownMenu = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();
  const history = useHistory();

  const [isActive, setIsActive] = useState(false);

  return (
    <Box ml={[3]} flexShrink={0} sx={{ position: "relative" }}>
      <Button
        bg="midnight"
        onClick={() => setIsActive(!isActive)}
        sx={{
          py: [2, 3],
          px: [1, 2],
          width: [40, 56]
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Button>

      <Dropdown isActive={isActive} minWidth={200} alignment="right">
        <Button
          width="100%"
          variant="outline"
          sx={{ borderRadius: 0 }}
          onClick={() => history.push(`/${artist.slug}/invite`)}
        >
          {t("artist.invite.title")}
        </Button>
      </Dropdown>
    </Box>
  );
};

export default DropdownMenu;
