import React, { useEffect } from "react";
import useAxios from "axios-hooks";
import { Heading, Box, Text } from "rebass";
import { useToasts } from "react-toast-notifications";
import useArtist from "../../../hooks/artist";
import Layout from "../../../components/_layout";
import Container from "../../../components/_layout/container";
import Loader from "../../../components/_layout/loader";
import ArtistInfo from "../../../components/artist-info";
import { useTranslation } from "react-i18next";

const Prize = ({ match }) => {
  const { artist } = useArtist();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [{ data: prize, loading: isLoading, error: isErrored }] = useAxios(
    `/api/prizes/id/${match.params.prizeId}`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  return isLoading ? (
    <Loader />
  ) : (
    <Layout
      coverUrl={prize.data.prize.picture}
      historyBackUrl={`/${artist.slug}/prizes`}
      logo={null}
      title={prize.data.prize.title}
      description={t("layout.artist_description", { artist: artist.name })}
    >
      <Container>
        <ArtistInfo />

        <Box my={[4]}>
          <Heading fontSize={[3, 4]}>{prize.data.prize.title}</Heading>

          <Text fontSize={[2, 3]} color="shadowBlue" mt={[2, 3]}>
            {prize.data.prize.description}
          </Text>
        </Box>
      </Container>
    </Layout>
  );
};

export default Prize;
