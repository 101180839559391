import React from "react";
import { Box, Card, Flex, Heading, Image } from "rebass";
import PropTypes from "prop-types";
import PointsImage from "../../images/points.svg";
import Avatar from "../_layout/avatar";
import NavLink from "../_layout/nav-link";

const RankingList = ({ list, mt = [3, 4] }) => {
  return (
    <Box mt={mt}>
      {list.map((el, k) => (
        <NavLink
          key={k}
          sx={{ display: "block", p: 0, mt: k !== 0 && [2, 3] }}
          mb={[2, 3]}
          link={el.slug ? `/${el.slug}/rankings` : null}
        >
          <Card color="text" p={[3, 4]} sx={{ borderRadius: 8 }} mb={[2, 3]}>
            <Flex alignItems="center">
              <Flex mr={[2, 3]} flexShrink={0}>
                <Avatar
                  userPicture={el.userPicture}
                  userName={el.userName}
                  size={[40, 50]}
                  borderRadius="50%"
                />
              </Flex>

              <Flex flexGrow={1} pr={[2, 3]}>
                <Heading
                  fontSize={[2, 3]}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "400"
                  }}
                >
                  {el.name}
                </Heading>
              </Flex>

              {el.points ? (
                <Flex mr={[3, 4]} flexShrink={0}>
                  <Flex justifyContent="center" alignItems="center">
                    <Heading fontSize={[1, 2]} mr={[1, 2]}>
                      {el.points}
                    </Heading>

                    <Image src={PointsImage} sx={{ width: 20 }} />
                  </Flex>
                </Flex>
              ) : null}

              <Flex maxWidth={[60, 70]} flexShrink={0}>
                <Heading fontSize={[3, 4]}>
                  {el.pos ? `${el.pos}°` : "--"}
                </Heading>
              </Flex>
            </Flex>
          </Card>
        </NavLink>
      ))}
    </Box>
  );
};

RankingList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string,
      userPicture: PropTypes.string,
      userName: PropTypes.string,
      points: PropTypes.number,
      pos: PropTypes.number
    })
  ).isRequired
};

export default RankingList;
