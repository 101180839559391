import React, { useEffect } from "react";
import _ from "lodash";
import { Heading, Box, Flex, Text } from "rebass";
import useAxios from "axios-hooks";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Container from "../../../components/_layout/container";
import Slider from "../../../components/_layout/slider";
import NavLink from "../../../components/_layout/nav-link";
import { abbreviateNumber } from "../../../utils";

const ArtistsCarousel = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [{ data: artists, loading: isLoading, error: isErrored }] = useAxios(
    `/api/artists`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  const getArtists = () => _.get(artists, "data.artists", []);

  const items = () =>
    getArtists().map((el, k) => (
      <Box data-testid="slider-item" key={k} variant="sliderItem">
        <NavLink link={`${el.slug}`} variant="sliderItem.link">
          <Flex
            sx={{
              backgroundImage: `url(${el.picture})`
            }}
            variant="sliderItem.imageContainer"
          >
            <Box variant="sliderItem.gradientContainer">
              <Heading>{el.name}</Heading>
              <Text>
                {t("home.artists_carousel.followers", {
                  count: abbreviateNumber(el.followers)
                })}
              </Text>
            </Box>
          </Flex>
        </NavLink>
      </Box>
    ));

  return (
    <Container px={null} pr={null}>
      <Heading fontSize={[3, 4]}>{t("home.artists_carousel.artists")}</Heading>

      {!isLoading && getArtists().length ? (
        <Box mt={[3]}>
          <Slider
            items={items()}
            append={
              <Box alignSelf="center" sx={{ textAlign: "center" }}>
                <NavLink link={"/artists"}>
                  <FontAwesomeIcon icon={faList} size="2x" />
                  <Text mt={3}>{t("home.artists_carousel.view_all")}</Text>
                </NavLink>
              </Box>
            }
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default ArtistsCarousel;
