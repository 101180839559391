import React from "react";
import { Flex, Box, Button, Text, Link } from "rebass";
import { Input } from "@rebass/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faFacebookMessenger
} from "@fortawesome/free-brands-svg-icons";
import useAuth from "../../../hooks/auth";
import { useToasts } from "react-toast-notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import Avatar from "../../../components/_layout/avatar";

const InvitationBlock = ({ artist }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const user = auth.user;
  const socialInvitationLink = `${config.landing}?artist=${artist.slug}%26user=${user.hashid}`;
  const invitationLink = `${config.landing}?artist=${artist.slug}&user=${user.hashid}`;

  return (
    <Flex
      flexWrap="wrap"
      my={[4, "48px"]}
      sx={{
        borderRadius: 8,
        bg: "secondary",
        boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
      }}
      p={["20px", "48px"]}
    >
      <Box px={2} py={2} width={1} mb={[2]}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="row-reverse"
        >
          <Box
            width={[100, 200]}
            height={[100, 200]}
            ml={[-4]}
            sx={{
              backgroundImage: `url(${artist.picture})`,
              backgroundSize: "cover",
              bg: "gray",
              borderRadius: "50%"
            }}
          />
          <Avatar
            size={[100, 200]}
            borderRadius={"50%"}
            fontSize={[4, 5, 6]}
            userPicture={auth.user.picture}
            userName={auth.user.name}
          />
        </Flex>
      </Box>

      <Box p={[2, 3]} width={1}>
        <Link
          href={`https://api.whatsapp.com/send?text=${t(
            "invitation.whatsapp_text",
            { link: socialInvitationLink }
          )}`}
          target="_blank"
        >
          <Button
            bg="whatsapp"
            color="white"
            p={[2, 3]}
            variant="primary"
            width="100%"
            fontSize={[2, 3]}
          >
            <Text display="inline" mr={2}>
              WhatsApp
            </Text>
            <FontAwesomeIcon icon={faWhatsapp} />
          </Button>
        </Link>
      </Box>

      <Box p={[2, 3]} width={1 / 2}>
        <Link
          href={`https://www.facebook.com/dialog/share?app_id=${config.fb_app_id}&display=popup&href=${socialInvitationLink}`}
          target="_blank"
        >
          <Button
            bg="facebook"
            color="white"
            p={[2, 3]}
            variant="primary"
            width="100%"
            fontSize={[2, 3]}
          >
            <Text display="inline" mr={2}>
              Facebook
            </Text>
            <FontAwesomeIcon icon={faFacebook} />
          </Button>
        </Link>
      </Box>

      <Box p={[2, 3]} width={1 / 2}>
        <Link
          href={`https://www.facebook.com/dialog/share?app_id=${config.fb_app_id}&display=popup&href=${socialInvitationLink}`}
          target="_blank"
        >
          <Button
            bg="messenger"
            color="white"
            p={[2, 3]}
            variant="primary"
            width="100%"
            fontSize={[2, 3]}
          >
            <Text display="inline" mr={2}>
              Messenger
            </Text>
            <FontAwesomeIcon icon={faFacebookMessenger} />
          </Button>
        </Link>
      </Box>

      <Flex p={[2, 3]} width={1}>
        <Input
          p={[2, 3]}
          bg="background"
          sx={{
            border: "none",
            boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
          }}
          fontSize={[1, 2, 3]}
          id="invitation-link"
          name="invitation-link"
          type="text"
          value={invitationLink}
          disabled
          readonly
        />

        <CopyToClipboard
          text={invitationLink}
          onCopy={() => {
            addToast("Link copiato!", {
              appearance: "success",
              autoDismiss: true
            });
          }}
        >
          <Button
            p={[1]}
            variant="outline"
            width={["30%", "20%"]}
            fontSize={[0, 1, 2]}
            sx={{
              borderRight: "none",
              boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
              fontWeight: "800",
              textTransform: "uppercase"
            }}
          >
            {t("invitation.copy")}
          </Button>
        </CopyToClipboard>
      </Flex>
    </Flex>
  );
};

export default InvitationBlock;
