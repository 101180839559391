import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import useAxios from "axios-hooks";
import { Button, Heading, Box, Flex, Text } from "rebass";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import useArtist from "../../hooks/artist";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import Loader from "../../components/_layout/loader";
import NavLink from "../../components/_layout/nav-link";
import ArtistInfo from "../../components/artist-info";

const Prizes = () => {
  const { artist } = useArtist();
  const { name } = artist;
  const { t } = useTranslation();

  const [prizes, setPrizes] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [{ data: prizesData, loading: isLoading }] = useAxios(
    `/api/prizes/${artist.slug}`
  );

  const getPrizes = useCallback(() => _.get(prizesData, "data.prizes", []), [
    prizesData
  ]);

  const [
    { data: nextPagePrizesData, loading: areNextPrizesLoading },
    executeNextPageCall
  ] = useAxios(nextPageUrl, { manual: true });

  const getNextPagePrizes = useCallback(
    () => _.get(nextPagePrizesData, "data.prizes", {}),
    [nextPagePrizesData]
  );

  useEffect(() => {
    if (!prizesData) {
      return;
    }

    setPrizes(getPrizes().data);
    setNextPageUrl(getPrizes().next_page_url);
  }, [prizesData, getPrizes]);

  useEffect(() => {
    if (!nextPagePrizesData) {
      return;
    }

    setPrizes(pzs => pzs.concat(getNextPagePrizes().data));
    setNextPageUrl(getNextPagePrizes().next_page_url);
  }, [nextPagePrizesData, getNextPagePrizes]);

  return isLoading ? (
    <Loader />
  ) : (
    <Layout
      coverUrl={artist.picture}
      historyBackUrl={`/${artist.slug}`}
      logo={null}
      title={t("layout.artist_prizes", { artist: name })}
      description={t("layout.artist_description", { artist: name })}
    >
      <Container>
        <ArtistInfo />

        <Box mt={[4]} mb={[3]}>
          <Heading fontSize={[3, 4]}>{t("prizes.title")}</Heading>

          <Text fontSize={[1, 2, 3]} color="shadowBlue" mt={[2, 3]}>
            {t("prizes.subtitle")}
          </Text>
        </Box>

        <Flex flexWrap="wrap" mx={-1} mt={[3, 4]}>
          {prizes.map((prize, key) => (
            <Box key={key} width={[1 / 2]} p={[1]}>
              <Box variant="sliderItem">
                <NavLink
                  link={`/${artist.slug}/prizes/${prize.id}`}
                  variant="sliderItem.link"
                >
                  <Flex
                    sx={{
                      backgroundImage: `url(${prize.picture})`
                    }}
                    variant="sliderItem.imageContainer"
                  >
                    <Box variant="sliderItem.gradientContainer">
                      <Heading>{prize.title}</Heading>
                      <Text>
                        {prize.start_position !== prize.end_position
                          ? t("artist.prizes_multiple_rank", {
                              start: prize.start_position,
                              end: prize.end_position
                            })
                          : t("artist.prizes_unique_rank", {
                              start: prize.start_position
                            })}
                      </Text>
                    </Box>
                  </Flex>
                </NavLink>
              </Box>
            </Box>
          ))}
        </Flex>

        {nextPageUrl ? (
          <Button
            disabled={areNextPrizesLoading}
            mt={[3, 4]}
            py={[2, 3]}
            px={[1, 2]}
            width="100%"
            variant="outline"
            onClick={() => executeNextPageCall()}
          >
            {areNextPrizesLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
            ) : (
              t("actions.load_more")
            )}
          </Button>
        ) : null}
      </Container>
    </Layout>
  );
};

export default Prizes;
