import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ArtistProvider } from "./contexts/artist";
import useAuth from "./hooks/auth";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import RequestPasswordReset from "./pages/request-password-reset";
import PasswordReset from "./pages/password-reset";
import Artists from "./pages/artists";
import Artist from "./pages/artist";
import Rankings from "./pages/artist-rankings";
import Invitation from "./pages/artist-invitation";
import Prizes from "./pages/artist-prizes";
import Prize from "./pages/artist/prize";
import Transactions from "./pages/artist-transactions";
import Styleguide from "./pages/styleguide";
import Faq from "./pages/faq";

const PrivateRoute = props => {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...props}>{props.children}</Route>;
};

const RouterComponent = () => {
  const auth = useAuth();

  return (
    <Router>
      <Switch>
        <Route exact path="/styleguide">
          <Styleguide />
        </Route>

        <Route
          exact
          path="/login"
          children={() => {
            if (auth.isAuthenticated && !auth.isLoading) {
              return <Redirect to="/" />;
            }

            return <Login />;
          }}
        />

        <Route
          exact
          path="/login/reset"
          children={() => {
            if (auth.isAuthenticated && !auth.isLoading) {
              return <Redirect to="/" />;
            }

            return <RequestPasswordReset />;
          }}
        />

        <Route
          exact
          path="/login/reset/:code"
          children={() => {
            if (auth.isAuthenticated && !auth.isLoading) {
              return <Redirect to="/" />;
            }

            return <PasswordReset />;
          }}
        />

        <Route
          exact
          path="/register"
          children={() => {
            if (auth.isAuthenticated && !auth.isLoading) {
              return <Redirect to="/" />;
            }

            return <Register />;
          }}
        />

        <Route
          exact
          path="/register/:artist/:hashid"
          children={({ match }) => {
            window.sessionStorage.setItem(
              "invitation_artist",
              match.params.artist
            );

            window.sessionStorage.setItem(
              "invitation_user",
              match.params.hashid
            );

            return <Redirect to="/register" />;
          }}
        />

        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>

        <PrivateRoute exact path="/artists">
          <Artists />
        </PrivateRoute>

        <PrivateRoute exact path="/faq">
          <Faq />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:artistName"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist"
              slug={match.params.artistName}
            >
              <Artist />
            </ArtistProvider>
          )}
        />

        <PrivateRoute
          exact
          path="/:artistName/rankings"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist/rankings"
              slug={match.params.artistName}
            >
              <Rankings />
            </ArtistProvider>
          )}
        />

        <PrivateRoute
          exact
          path="/:artistName/prizes"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist/prizes"
              slug={match.params.artistName}
            >
              <Prizes />
            </ArtistProvider>
          )}
        />

        <PrivateRoute
          exact
          path="/:artistName/prizes/:prizeId"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist/prizes"
              slug={match.params.artistName}
            >
              <Prize match={match} />
            </ArtistProvider>
          )}
        />

        <PrivateRoute
          exact
          path="/:artistName/actions"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist/actions"
              slug={match.params.artistName}
            >
              <Transactions />
            </ArtistProvider>
          )}
        />

        <PrivateRoute
          exact
          path="/:artistName/invite"
          children={({ match }) => (
            <ArtistProvider
              routeContext="artist/invite"
              slug={match.params.artistName}
            >
              <Invitation />
            </ArtistProvider>
          )}
        ></PrivateRoute>
      </Switch>
    </Router>
  );
};

export default RouterComponent;
