import React from "react";
import { Flex, Box } from "rebass";
import UserPosition from "./user-position";

const Podium = ({ topUsers }) => {
  return (
    <Box mt={[5, 6]} mb={[4, 5]}>
      <Flex alignItems="center" justifyContent="space-evenly">
        <Flex
          justifyContent="center"
          px={2}
          flexDirection="column"
          alignItems="center"
        >
          <UserPosition rank={topUsers[1]} />
        </Flex>
        <Flex
          justifyContent="center"
          px={2}
          flexDirection="column"
          alignItems="center"
          mx={[2, 3, 4]}
        >
          <UserPosition rank={topUsers[0]} size={[120, 140, 220]} />
        </Flex>
        <Flex
          justifyContent="center"
          px={2}
          flexDirection="column"
          alignItems="center"
        >
          <UserPosition rank={topUsers[2]} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Podium;
