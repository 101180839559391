import React from "react";
import { Heading, Box, Image } from "rebass";
import PointsImage from "../../../../images/points.svg";
import _ from "lodash";

const UserPosition = ({ rank, size = [80, 120, 160] }) => {
  const badgeColor = rank => {
    switch (rank.rank) {
      case 1:
        return "gold";
      case 2:
        return "silver";
      case 3:
        return "bronze";
      default:
        return null;
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${rank ? rank.user.picture : ""})`,
          position: "relative",
          backgroundSize: "cover",
          border: "none",
          boxShadow: "0 3px 6px 0 rgba(0,0,0,.16)",
          width: size,
          height: size,
          borderRadius: ["50%"],
          bg: "secondary"
        }}
      >
        {rank ? (
          <Box
            sx={{
              position: "absolute",
              left: _.map(size, s => s / 1.5),
              boxShadow: "0 3px 6px 0 rgba(0,0,0,.16)",
              width: _.map(size, s => s / 3),
              height: _.map(size, s => s / 3),

              borderRadius: ["50%"],
              bg: badgeColor(rank),
              textAlign: "center"
            }}
          >
            <Heading
              fontSize={rank.rank === 1 ? [4, 5] : [1, 2, 3]}
              sx={{ lineHeight: _.map(size, s => `${s / 3}px`) }}
            >
              {rank.rank}
            </Heading>
          </Box>
        ) : null}
      </Box>
      <Heading
        p={[2]}
        fontSize={[0, 2, 3]}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "center",
          textOverflow: "ellipsis",
          maxWidth: [60, 90, 100]
        }}
      >
        {rank ? rank.user.name : null}
      </Heading>
      <Heading fontSize={[0, 1, 2]}>
        {rank ? rank.points : "0"}{" "}
        <Image
          src={PointsImage}
          sx={{
            width: [12, 14, 16]
          }}
        />
      </Heading>
    </>
  );
};

export default UserPosition;
