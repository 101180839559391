import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import { Box, Heading, Text } from "rebass";
import Accordion from "./accordion";
import _ from "lodash";
import Loader from "../../components/_layout/loader";
import useAxios from "axios-hooks";

const Faq = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [{ data: faq, loading: isLoading, error: isErrored }] = useAxios(
    `/api/faq`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  const getFaq = () => _.get(faq, "data.faq", []);

  return isLoading ? (
    <Loader />
  ) : (
    <Layout historyBackUrl="/" title={t("home.faq_title")}>
      <Container>
        <Box my={[3]}>
          <Heading fontSize={[3, 4]}>{t("faq.title")}</Heading>
          <Text fontSize={[1, 2, 3]} color="shadowBlue" mt={[2, 3]}>
            {t("faq.subtitle")}
          </Text>

          <Accordion list={getFaq()} />
        </Box>
      </Container>
    </Layout>
  );
};

export default Faq;
