import React from "react";
import { Styled } from "theme-ui";
import { TypeScale, TypeStyle, ColorPalette } from "@theme-ui/style-guide";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";

export default () => (
  <Layout>
    <Container>
      <Styled.h1>Style Guide</Styled.h1>

      <Styled.h2>Color Palette</Styled.h2>
      <ColorPalette />

      <Styled.h2 style={{ marginTop: 64 }}>Type Scale</Styled.h2>
      <TypeScale />

      <Styled.h2 style={{ marginTop: 64 }}>Type Specimens</Styled.h2>
      <TypeStyle
        fontFamily="heading"
        fontWeight="heading"
        lineHeight="heading"
        fontSize={3}
      >
        Heading
      </TypeStyle>
      <TypeStyle
        fontFamily="monospace"
        fontWeight="monospace"
        lineHeight="monospace"
        fontSize={3}
      >
        Monospace
      </TypeStyle>
      <TypeStyle
        fontFamily="body"
        fontWeight="body"
        lineHeight="body"
        fontSize={3}
      >
        Body
      </TypeStyle>
    </Container>
  </Layout>
);
