import React, { useState } from "react";
import { Box, Button, Card, Flex, Image, Text, Link, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import Container from "../../components/_layout/container";
import useAuth from "../../hooks/auth";
import logo from "../../images/logo-light.svg";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const PasswordReset = ({ match }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, watch, handleSubmit, setError, errors } = useForm();

  const onSubmit = async data => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await auth.resetPassword({
        data: {
          email: data.email,
          password: data.password,
          code: match.params.code
        }
      });

      setIsSubmitted(true);
    } catch (e) {
      setIsLoading(false);

      if (e.status === 422) {
        setError(
          "server",
          "invalidData",
          t("reset-password-code.errors.invalid_data")
        );
      } else {
        setError("server", "error", "Server error, please retry.");
      }
    }

    setIsLoading(false);
  };

  return (
    <Container maxWidth={400}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Flex flexDirection="column" alignItems="center" mb={[4]} p={[2]}>
          <Image
            src={logo}
            sx={{
              width: ["130px"]
            }}
            mb={[3]}
          />
          <Heading textAlign="center">{t("reset-password-code.title")}</Heading>
          <Text textAlign="center" fontSize={[0, 1, 2]} mt={[2]}>
            {t("reset-password-code.subtitle")}
          </Text>
        </Flex>

        {errors.server && (
          <Card
            data-testid={`server-${errors.server.type}`}
            width="100%"
            bg="danger"
            sx={{
              borderRadius: 8,
              textAlign: "center"
            }}
            color="white"
            mb={3}
            p={3}
          >
            {errors.server.message}
          </Card>
        )}

        <Card
          width="100%"
          p={4}
          sx={{
            borderRadius: 8,
            boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
          }}
        >
          {isSubmitted ? (
            <>
              <Heading>{t("reset-password-code.success_title")}</Heading>
              <Text fontSize={[0, 1, 2]}>
                {t("reset-password-code.success_subtitle")}
              </Text>
              <Link href="/login">{t("register.cta")}</Link>
            </>
          ) : (
            <form
              data-testid={`auth-${
                auth.isAuthenticated ? "isLogged" : "isNotLogged"
              }`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box>
                <Label
                  htmlFor="email"
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: "shadowBlue"
                  }}
                  fontSize={[0]}
                >
                  {t("register.label.email")}
                </Label>
                <Input
                  bg="background"
                  sx={{
                    border: "none",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                    color: "shadowBlue"
                  }}
                  mt={[1]}
                  data-testid="email"
                  name="email"
                  type="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address"
                    }
                  })}
                />
                {errors.email && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="password-missing"
                  >
                    {t("register.errors.invalid_email")}
                  </Text>
                )}
              </Box>

              <Box mt={3}>
                <Label
                  htmlFor="password"
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: "shadowBlue"
                  }}
                  fontSize={[0]}
                >
                  {t("register.label.password")}
                </Label>
                <Input
                  bg="background"
                  sx={{
                    border: "none",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                    color: "shadowBlue"
                  }}
                  mt={[1]}
                  data-testid="password"
                  name="password"
                  type="password"
                  ref={register({
                    required: true,
                    minLength: 6
                  })}
                />
                {errors.password && errors.password.type === "required" && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="password-missing"
                  >
                    {t("register.errors.missing_password")}
                  </Text>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="password-min-length"
                  >
                    {t("register.errors.short_password")}
                  </Text>
                )}
              </Box>

              <Box mt={3}>
                <Label
                  htmlFor="confirm_password"
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: "shadowBlue"
                  }}
                  fontSize={[0]}
                >
                  {t("register.label.confirm_password")}
                </Label>
                <Input
                  bg="background"
                  sx={{
                    border: "none",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                    color: "shadowBlue"
                  }}
                  mt={[1]}
                  data-testid="confirm_password"
                  name="confirm_password"
                  type="password"
                  ref={register({
                    validate: value => value === watch("password")
                  })}
                />
                {errors.confirm_password && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="passwords-dont-match"
                  >
                    {t("register.errors.unmatched_password")}
                  </Text>
                )}
              </Box>

              <Box mt={3}>
                <Button
                  disabled={isLoading}
                  data-testid="submit"
                  width="100%"
                  type="submit"
                  p={[3]}
                  variant="shadowBlue"
                  fontSize={[0, 1]}
                  sx={{
                    fontWeight: 800,
                    textTransform: "uppercase",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                  }}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faCircleNotch}></FontAwesomeIcon>
                  ) : (
                    t("reset-password-code.submit")
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Card>
      </Flex>
    </Container>
  );
};

export default PasswordReset;
