import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Flex, Image, Heading, Card, Text } from "rebass";
import Container from "../../../components/_layout/container";
import PointsImage from "../../../images/points.svg";
import UsersImage from "../../../images/users.svg";
import AudioImage from "../../../images/audio.svg";

const Campaigns = ({ artist }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container>
      <Box mt={[4]}>
        <Card variant="ctaCard">
          <Flex>
            <Box flexGrow={1}>
              <Text variant="ctaCard.topTitle">{t("artist.fan.title")}</Text>
              <Heading variant="ctaCard.title">
                {t("artist.fan.subtitle")}
              </Heading>
            </Box>
          </Flex>

          <Text variant="ctaCard.description">
            {t("artist.fan.description")}
          </Text>
        </Card>

        <Card
          variant="ctaCard"
          mt={[3, 4]}
          onClick={() => {
            history.push(`/${artist.slug}/invite`);
          }}
        >
          <Flex>
            <Box flexGrow={1}>
              <Text variant="ctaCard.topTitle">
                {t("artist.invite.top_title", {
                  artistName: artist.name.toUpperCase()
                })}
              </Text>

              <Heading variant="ctaCard.title">
                {t("artist.invite.title")}
              </Heading>
            </Box>

            <Box variant="ctaCard.iconContainer">
              <Image src={UsersImage} />
            </Box>
          </Flex>

          <Text variant="ctaCard.description">
            {t("artist.invite.description", {
              artistName: artist.name
            })}{" "}
            <Image src={PointsImage} sx={{ width: 13 }} />
          </Text>
        </Card>

        <Card
          variant="ctaCard"
          mt={[3, 4]}
          onClick={() => {
            history.push(`/${artist.slug}/actions`);
          }}
        >
          <Flex>
            <Box flexGrow={1}>
              <Text variant="ctaCard.topTitle">
                {t("artist.actions.top_title")}
              </Text>

              <Heading variant="ctaCard.title">
                {t("artist.actions.title")}
              </Heading>
            </Box>

            <Box variant="ctaCard.iconContainer">
              <Image src={AudioImage} width={32} />
            </Box>
          </Flex>

          <Text variant="ctaCard.description">
            {t("artist.actions.description", {
              artistName: artist.name
            })}
          </Text>
        </Card>
      </Box>
    </Container>
  );
};

export default Campaigns;
