import React from "react";
import { Heading, Box, Flex, Button, Text } from "rebass";
import { useHistory } from "react-router-dom";
import useArtist from "../../../hooks/artist";
import Container from "../../../components/_layout/container";
import NavLink from "../../../components/_layout/nav-link";
import { useTranslation } from "react-i18next";

const Prizes = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();
  const history = useHistory();

  return artist.prizes && artist.prizes.length ? (
    <Container>
      <Box mt={[4, 5]} mb={[3]}>
        <Heading fontSize={[3, 4]}>{t("prizes.title")}</Heading>
      </Box>

      <Flex flexWrap="wrap" mx={-1} mt={[3, 4]}>
        {artist.prizes.map((prize, key) => (
          <Box key={key} width={[1 / 2]} p={[1]}>
            <Box variant="sliderItem">
              <NavLink
                link={`/${artist.slug}/prizes/${prize.id}`}
                variant="sliderItem.link"
              >
                <Flex
                  sx={{
                    backgroundImage: `url(${prize.picture})`
                  }}
                  variant="sliderItem.imageContainer"
                >
                  <Box variant="sliderItem.gradientContainer">
                    <Heading>{prize.title}</Heading>

                    <Text>
                      {prize.start_position !== prize.end_position
                        ? t("artist.prizes_multiple_rank", {
                            start: prize.start_position,
                            end: prize.end_position
                          })
                        : t("artist.prizes_unique_rank", {
                            start: prize.start_position
                          })}
                    </Text>
                  </Box>
                </Flex>
              </NavLink>
            </Box>
          </Box>
        ))}
      </Flex>

      {artist.prizes_count > 4 && (
        <Button
          variant="outline"
          mt={[3]}
          width="100%"
          onClick={() => history.push(`/${artist.slug}/prizes`)}
        >
          {t("prizes.show_all")}
        </Button>
      )}
    </Container>
  ) : null;
};

export default Prizes;
