import React from "react";
import { Heading, Box, Text } from "rebass";
import useArtist from "../../hooks/artist";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import ArtistInfo from "../../components/artist-info";
import InvitationBlock from "./invitation-block";
import { useTranslation } from "react-i18next";

const Invitation = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();
  const { name } = artist;

  return (
    <Layout
      coverUrl={artist.cover_picture || artist.picture}
      historyBackUrl={`/${artist.slug}`}
      logo={null}
      title={t("artist.invite.title")}
      description={t("layout.artist_description", { artist: name })}
    >
      <Container>
        <ArtistInfo />

        <Box my={[3, 4]}>
          <Heading fontSize={[3, 4]}>{t("invitation.title")}</Heading>
          <Text fontSize={[1, 2, 3]} color="shadowBlue" mt={[2]}>
            {t("invitation.subtitle", { artist: artist.name })}
          </Text>
        </Box>

        <Box mt={[4, 5]}>
          <InvitationBlock artist={artist} />
        </Box>
      </Container>
    </Layout>
  );
};

export default Invitation;
