import React from "react";
import { Link as RebassLink } from "rebass";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const NavLink = ({ link, sx, variant = "nav", children }) => {
  if (link) {
    return (
      <RebassLink variant={variant} to={link} as={RouterLink} {...{ sx }}>
        {children}
      </RebassLink>
    );
  }

  return children;
};

NavLink.propTypes = {
  link: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default NavLink;
