import React, { useState } from "react";
import { Box, Card, Flex, Heading, Text } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useThemeUI } from "theme-ui";

const AccordionItem = ({ data, mt }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useThemeUI();

  const cssIcon = {
    transition: "transform 0.3s ease-in-out",
    transform: isOpen ? "rotate(180deg)" : null
  };

  const cssWrapperDescription = {
    maxHeight: isOpen ? "100rem" : 0,
    overflow: isOpen ? "auto" : "hidden",
    transitionDuration: "0.5s",
    transitionTimingFunction: isOpen
      ? "cubic-bezier(0.895, 0.03, 0.685, 0.22)"
      : null,
    transitionProperty: "max-height",
    textTransform: isOpen ?? "cubic-bezier(0.95, 0.05, 0.795, 0.035)"
  };

  const cssDescription = {
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translateY(0)" : "translateY(-1rem)",
    transitionTimingFunction: isOpen ? "ease-in-out" : "linear, ease",
    transitionDuration: isOpen ? "0.2s" : "0.1s",
    transitionProperty: "opacity, transform",
    transitionDelay: isOpen ? "0.2s" : "0.5s"
  };

  return (
    <Card
      color="text"
      mt={mt}
      p={0}
      sx={{ borderRadius: 8 }}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" p={[3]}>
          <Heading fontSize={[1, 2]} color={theme.colors.primary}>
            {data.title}
          </Heading>

          <Box ml={1} sx={cssIcon}>
            <FontAwesomeIcon
              color={theme.colors.primary}
              icon={faChevronDown}
            ></FontAwesomeIcon>
          </Box>
        </Flex>

        <Flex
          css={cssWrapperDescription}
          sx={{ borderTop: `4px solid ${theme.colors.background}` }}
        >
          <Box mt={[0, 1]} css={cssDescription} p={3}>
            <Text fontSize={[0, 1]} color="text">
              {data.description}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AccordionItem;
