import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { Text, Box, Image, Heading, Button } from "rebass";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "axios-hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useArtist from "../../hooks/artist";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import Loader from "../../components/_layout/loader";
import ArtistInfo from "../../components/artist-info";
import RankingList from "../../components/ranking-list";
import Podium from "./podium";
import PointsImage from "../../images/points.svg";
import EmptyList from "../../components/_layout/empty-list";

const Ranking = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();
  const history = useHistory();
  const { name } = artist;

  const [rankings, setRankings] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [{ data: rankingsData, loading: areRankingsLoading }] = useAxios(
    `/api/rankings/${artist.slug}/current`
  );

  const getRankings = useCallback(
    () => _.get(rankingsData, "data.ranking", {}),
    [rankingsData]
  );

  const [
    { data: nextPageRankingsData, loading: areNextRankingsLoading },
    executeNextPageCall
  ] = useAxios(nextPageUrl, { manual: true });

  const getNextPageRankings = useCallback(
    () => _.get(nextPageRankingsData, "data.ranking", {}),
    [nextPageRankingsData]
  );

  useEffect(() => {
    if (!rankingsData) {
      return;
    }

    setRankings(getRankings().data);
    setNextPageUrl(getRankings().next_page_url);
  }, [rankingsData, getRankings]);

  useEffect(() => {
    if (!nextPageRankingsData) {
      return;
    }

    setRankings(r => r.concat(getNextPageRankings().data));
    setNextPageUrl(getNextPageRankings().next_page_url);
  }, [nextPageRankingsData, getNextPageRankings]);

  const takeTopUsers = () => {
    return _.slice(rankings, 0, 3);
  };

  const takeNormalUsers = () => {
    return _.slice(rankings, 3);
  };

  if (areRankingsLoading) {
    return <Loader />;
  }

  return (
    <Layout
      coverUrl={artist.picture}
      historyBackUrl={`/${artist.slug}`}
      logo={null}
      title={t("layout.artist_ranking", { artist: name })}
      description={t("layout.artist_description", { artist: name })}
    >
      <Container>
        <ArtistInfo />

        <Box mt={[3]}>
          <Heading fontSize={[3, 4]}>{t("ranking.title")}</Heading>

          <Text fontSize={[1, 2, 3]} color="shadowBlue" mt={[2]}>
            {t("ranking.subtitle", { artist: artist.name })}
            <Image src={PointsImage} sx={{ width: 12 }} />
            {t("ranking.paragraph")}
          </Text>
        </Box>

        {takeTopUsers().length ? <Podium topUsers={takeTopUsers()} /> : null}

        {takeNormalUsers().length ? (
          <>
            <RankingList
              list={_.map(takeNormalUsers(), ranking => ({
                name: ranking.user.name,
                userPicture: ranking.user.picture,
                userName: ranking.user.name,
                points: ranking.points,
                pos: ranking.rank
              }))}
              mt={[2]}
            />

            {nextPageUrl ? (
              <Button
                disabled={areNextRankingsLoading}
                mt={[3, 4]}
                py={[2, 3]}
                px={[1, 2]}
                width="100%"
                variant="outline"
                onClick={() => executeNextPageCall()}
              >
                {areNextRankingsLoading ? (
                  <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
                ) : (
                  t("actions.load_more")
                )}
              </Button>
            ) : null}
          </>
        ) : null}

        {!rankings.length ? (
          <EmptyList>
            <Heading color="shadowBlue" fontSize={[1, 2, 3]}>
              {t("ranking.empty")}
            </Heading>

            <Button
              variant="outline"
              mt={[3]}
              onClick={() => history.push(`/${artist.slug}`)}
            >
              {t("actions.cta")}
            </Button>
          </EmptyList>
        ) : null}
      </Container>
    </Layout>
  );
};

export default Ranking;
