import React from "react";
import { Box } from "rebass";

import AccordionItem from "./accordion-item";

const Accordion = ({ list }) => {
  return (
    <Box mt={[4, 5]}>
      {list.map((el, k) => (
        <AccordionItem key={k} data={el} mt={k !== 0 && [2, 3]} />
      ))}
    </Box>
  );
};

export default Accordion;
