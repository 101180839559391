import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Heading, Text } from "rebass";
import NavLink from "../_layout/nav-link";
import { abbreviateNumber } from "../../utils";
import { useTranslation } from "react-i18next";

const ArtistList = ({ list }) => {
  const { t } = useTranslation();
  const items = () =>
    list.map((el, k) => (
      <Box data-testid="slider-item" key={k} variant="sliderItem">
        <NavLink link={`${el.slug}`} variant="sliderItem.link">
          <Flex
            sx={{
              backgroundImage: `url(${el.picture})`
            }}
            variant="sliderItem.imageContainer"
          >
            <Box variant="sliderItem.gradientContainer">
              <Heading>{el.name}</Heading>
              <Text>
                {t("home.artists_carousel.followers", {
                  count: abbreviateNumber(el.followers)
                })}
              </Text>
            </Box>
          </Flex>
        </NavLink>
      </Box>
    ));

  return (
    <Flex flexWrap="wrap" mx={-2} mt={[4, 5]}>
      {items().map((el, k) => (
        <Box width={[1 / 2]} key={k} p={[1]}>
          {el}
        </Box>
      ))}
    </Flex>
  );
};

ArtistList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ArtistList;
