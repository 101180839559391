import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Flex, Image, Box, Button, Text } from "rebass";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import NavLink from "./nav-link";
import useAuth from "../../hooks/auth";
import Dropdown from "../../components/_layout/dropdown";
import Logo from "../../images/logo-light.svg";
import Container from "./container";
import Avatar from "./avatar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Layout = ({
  children,
  logo = <Image src={Logo} width={[100]} />,
  coverUrl,
  historyBackUrl,
  title = null,
  description = null
}) => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const bgRef = useRef();
  const { t } = useTranslation();

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [coverOffset, setCoverOffset] = useState(0);

  const sidePanelWidth = [30, 40];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleScroll = () => {
    const posY = bgRef.current.getBoundingClientRect().top;
    const coverElementOffset = window.pageYOffset - posY;

    setCoverOffset(coverElementOffset);
  };

  useEffect(() => {
    if (!coverUrl) {
      return;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const Wrapper = ({ children }) => {
    if (coverUrl) {
      return (
        <Box variant="topCover">
          <Flex variant="topCover.menuContainer">{children}</Flex>

          <Flex
            variant="topCover.backgroundContainer"
            flexDirection="column"
            sx={{
              backgroundImage: `url(${coverUrl})`,
              backgroundPositionY: coverOffset / 7
            }}
            ref={bgRef}
          >
            <Box variant="topCover.gradientContainer" />
          </Flex>
        </Box>
      );
    }

    return (
      <Box
        pb={[3, 4]}
        sx={{
          paddingTop: [80, 90]
        }}
      >
        <Box ref={bgRef} />

        <Flex
          bg="secondary"
          color="shadowBlue"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: [70, 80],
            zIndex: 100
          }}
          px={[3, 4]}
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Flex>
      </Box>
    );
  };

  return (
    <Flex
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between"
      }}
    >
      <Helmet>
        <title>{title || t("layout.title")}</title>
        <meta
          name="description"
          content={description || t("layout.description")}
        />
      </Helmet>
      <Box>
        <Wrapper>
          <Container px={[0]} pl={[0]} pr={[0]}>
            <Flex justifyContent="space-between">
              {historyBackUrl ? (
                <Flex width={sidePanelWidth} alignItems="center">
                  <NavLink link={historyBackUrl}>
                    <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                  </NavLink>
                </Flex>
              ) : (
                <Flex width={sidePanelWidth} />
              )}

              {logo ? (
                <Flex
                  px={2}
                  flexGrow={1}
                  justifyContent="center"
                  sx={{
                    "> *": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  }}
                >
                  {logo}
                </Flex>
              ) : null}

              {auth.isAuthenticated ? (
                <Flex
                  onClick={() => setIsMenuActive(!isMenuActive)}
                  width={sidePanelWidth}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: "relative",
                    cursor: "pointer"
                  }}
                >
                  <Avatar
                    size={30}
                    borderRadius={"default"}
                    fontSize={0}
                    userPicture={auth.user.picture}
                    userName={auth.user.name}
                  />

                  <Dropdown
                    isActive={isMenuActive}
                    minWidth={100}
                    alignment="right"
                  >
                    <Button
                      width="100%"
                      variant="outline"
                      sx={{ borderRadius: 0 }}
                      onClick={() => auth.logout()}
                    >
                      Logout
                    </Button>
                  </Dropdown>
                </Flex>
              ) : (
                <Flex width={sidePanelWidth} />
              )}
            </Flex>
          </Container>
        </Wrapper>

        <div data-testid="layout-container">{children}</div>
      </Box>

      <Box mt={[4, 5]} p={[4, 5]} bg="secondary">
        <Text textAlign="center" color="shadowBlue">
          &copy; Mydly
        </Text>
      </Box>
    </Flex>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  logo: PropTypes.element,
  coverUrl: PropTypes.string,
  historyBackUrl: PropTypes.string
};

export default Layout;
