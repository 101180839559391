import React, { useEffect } from "react";
import _ from "lodash";
import useAxios from "axios-hooks";
import { Heading, Box, Text } from "rebass";
import { useToasts } from "react-toast-notifications";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import Loader from "../../components/_layout/loader";
import ArtistList from "../../components/artist-list";
import { useTranslation } from "react-i18next";

const Artists = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [{ data: artists, loading: isLoading, error: isErrored }] = useAxios(
    `/api/artists`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  const getArtists = () => _.get(artists, "data.artists", []);

  return isLoading ? (
    <Loader />
  ) : (
    <Layout historyBackUrl="/">
      <Container>
        <Box my={[3]}>
          <Heading fontSize={[3, 4]}>{t("artists.title")}</Heading>

          <Text fontSize={[1, 2, 3]} color="shadowBlue" mt={[2, 3]}>
            {t("artists.subtitle")}
          </Text>
        </Box>

        <ArtistList list={getArtists()} />
      </Container>
    </Layout>
  );
};

export default Artists;
