import axios from "axios";

export const redirectToSpotifyAuth = async ({ onError = () => {} }) => {
  try {
    const response = await axios.get(`/api/auth/connect/spotify`);

    window.location.href = response.data.data.authorization_url;
  } catch (e) {
    onError(e);
  }
};
