import React from "react";
import { Heading, Flex } from "rebass";
import { getAcronym } from "../../../utils";

const Avatar = ({
  size,
  userPicture,
  userName,
  borderRadius,
  fontSize = [1, 2, 3]
}) => {
  const backgroundImage = () => {
    if (!userPicture) {
      return null;
    }

    return {
      backgroundImage: `url(${userPicture})`,
      backgroundSize: "cover"
    };
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{
        ...backgroundImage(),
        bg: "shadowBlue",
        width: size,
        height: size,
        textAlign: "center",
        borderRadius
      }}
    >
      {!userPicture ? (
        <Heading
          color="background"
          sx={{
            textAlign: "center",
            lineHeight: 1,
            textTransform: "uppercase"
          }}
          fontSize={fontSize}
        >
          {getAcronym(userName)}
        </Heading>
      ) : null}
    </Flex>
  );
};

export default Avatar;
