import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Card, Flex, Image, Heading, Text, Link } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import Container from "../../components/_layout/container";
import useAuth from "../../hooks/auth";
import logo from "../../images/logo-light.svg";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Login = ({ history }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm();

  const onSubmit = async credentials => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await auth.login({
        credentials,
        onSuccess: () => {
          setIsLoading(false);

          history.push("/");
        }
      });
    } catch (e) {
      setIsLoading(false);

      if (e.status === 422) {
        setError("server", "noMatch", t("login.error.user_not_found"));
      } else {
        setError("server", "error", "Server error, please retry.");
      }
    }

    setIsLoading(false);
  };

  return (
    <Container maxWidth={400}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Flex flexDirection="column" alignItems="center" mb={[4]}>
          <Image
            src={logo}
            sx={{
              width: ["130px"]
            }}
            mb={[3]}
          />

          <Heading>{t("login.title")}</Heading>
          <Text fontSize={[0, 1, 2]} mt={[2]}>
            {t("login.subtitle")}
            <Link href="/register" ml={[1]}>
              {t("login.cta")}
            </Link>
          </Text>
        </Flex>

        {errors.server && (
          <Card
            data-testid={`server-${errors.server.type}`}
            bg="danger"
            sx={{
              textAlign: "center",
              borderRadius: 8
            }}
            color="white"
            mb={3}
            p={3}
          >
            {errors.server.message}
          </Card>
        )}

        <Card
          width="100%"
          p={4}
          color="shadowBlue"
          sx={{
            borderRadius: 8,
            boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
          }}
        >
          <form
            id="signin-form"
            data-testid={`auth-${
              auth.isAuthenticated ? "isLogged" : "isNotLogged"
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box>
              <Label
                htmlFor="email"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "shadowBlue"
                }}
                fontSize={[0]}
              >
                {t("register.label.email")}
              </Label>

              <Input
                mt={[1]}
                data-testid="email"
                name="email"
                type="email"
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                  color: "shadowBlue"
                }}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address"
                  }
                })}
              />

              {errors.email && errors.email.type === "pattern" && (
                <Text
                  color="danger"
                  fontSize={[0, 1, 2]}
                  data-testid="invalid-email-pattern"
                >
                  {t("register.errors.invalid_email")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Label
                htmlFor="password"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "shadowBlue"
                }}
                fontSize={[0]}
              >
                {t("register.label.password")}
              </Label>

              <Input
                mt={[1]}
                data-testid="password"
                name="password"
                type="password"
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                  color: "shadowBlue"
                }}
                ref={register({
                  required: true,
                  minLength: 6
                })}
              />

              {errors.password && errors.password.type === "required" && (
                <Text
                  color="danger"
                  fontSize={[0, 1, 2]}
                  data-testid="password-missing"
                >
                  {t("register.errors.missing_password")}
                </Text>
              )}

              {errors.password && errors.password.type === "minLength" && (
                <Text
                  color="danger"
                  fontSize={[0, 1, 2]}
                  data-testid="password-min-length"
                >
                  {t("register.errors.short_password")}
                </Text>
              )}
              <Box mt={[3]} fontSize={[0]}>
                <Link href="/login/reset" color="shadowBlue">
                  {t("login.forgot_password")}
                </Link>
              </Box>
            </Box>

            <Box mt={3}>
              <Button
                mt={[1]}
                variant="shadowBlue"
                fontSize={[0, 1]}
                p={[3]}
                sx={{
                  fontWeight: 800,
                  textTransform: "uppercase",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                }}
                disabled={isLoading}
                data-testid="submit"
                width="100%"
                type="submit"
              >
                {isLoading ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    size="1x"
                    spin
                  ></FontAwesomeIcon>
                ) : (
                  t("register.cta")
                )}
              </Button>
            </Box>
          </form>
        </Card>
      </Flex>
    </Container>
  );
};

export default withRouter(Login);
