import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en";
import it from "./languages/it";

const resources = {
  en: {
    translation: en
  },
  it: {
    translation: it
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "it",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
