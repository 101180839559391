import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Heading, Text, Box, Button } from "rebass";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "axios-hooks";
import { useTranslation, Trans } from "react-i18next";
import useArtist from "../../hooks/artist";
import Layout from "../../components/_layout/";
import Loader from "../../components/_layout/loader";
import Container from "../../components/_layout/container";
import ArtistInfo from "../../components/artist-info";
import TransactionList from "../../components/transaction-list";
import listenLogo from "../../images/audio.svg";
import invitationLogo from "../../images/users.svg";
import { useHistory } from "react-router-dom";
import EmptyList from "../../components/_layout/empty-list";
import { useCallback } from "react";

const Transactions = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();
  const history = useHistory();
  const { name } = artist;

  const [transactions, setTransactions] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [
    { data: transactionsData, loading: areTransactionsLoading }
  ] = useAxios(`/api/transactions/${artist.slug}`);

  const getTransactions = useCallback(
    () => _.get(transactionsData, "data.transactions", {}),
    [transactionsData]
  );

  const [
    { data: nextPageTransactionsData, loading: areNextTransactionsLoading },
    executeNextPageCall
  ] = useAxios(nextPageUrl, { manual: true });

  const getNextPageTransactions = useCallback(
    () => _.get(nextPageTransactionsData, "data.transactions", {}),
    [nextPageTransactionsData]
  );

  useEffect(() => {
    if (!transactionsData) {
      return;
    }

    setTransactions(getTransactions().data);
    setNextPageUrl(getTransactions().next_page_url);
  }, [transactionsData, getTransactions]);

  useEffect(() => {
    if (!nextPageTransactionsData) {
      return;
    }

    setTransactions(txs => txs.concat(getNextPageTransactions().data));
    setNextPageUrl(getNextPageTransactions().next_page_url);
  }, [nextPageTransactionsData, getNextPageTransactions]);

  const mapTransactionType = transaction => {
    switch (transaction.context_type) {
      case "App\\SimpleListening":
        return {
          name: t("actions.listening", {
            song: _.get(transaction, "context.track_name")
          }),
          img: listenLogo
        };
      case "App\\User":
        return {
          name: t("actions.invitation", { name: transaction.context.name }),
          img: invitationLogo
        };
      default:
        return null;
    }
  };

  if (areTransactionsLoading) {
    return <Loader />;
  }

  return (
    <Layout
      coverUrl={artist.cover_picture || artist.picture}
      historyBackUrl={`/${artist.slug}`}
      logo={null}
      title={t("layout.artist_actions", { artist: name })}
      description={t("layout.artist_description", { artist: name })}
    >
      <Container>
        <ArtistInfo />

        <Box mt={[4]} mb={[3]}>
          {!areTransactionsLoading && transactions.length ? (
            <>
              <Box mt={[3]}>
                <Heading fontSize={[3, 4]}>{t("actions.title")}</Heading>

                <Text fontSize={[0, 1]} color="shadowBlue" mt={[3]}>
                  <Trans i18nKey="actions.subtitle">
                    Have you just listened <strong>{{ name }}</strong> and don't
                    see the action on this list? Don't panic! This list updates
                    every 25 minutes
                  </Trans>
                </Text>
              </Box>

              <TransactionList
                list={_.map(transactions, t => ({
                  name: mapTransactionType(t).name,
                  points: t.points,
                  date: moment.utc(t.created_at).fromNow(),
                  img: mapTransactionType(t).img
                }))}
              />

              {nextPageUrl ? (
                <Button
                  disabled={areNextTransactionsLoading}
                  mt={[3, 4]}
                  py={[2, 3]}
                  px={[1, 2]}
                  width="100%"
                  variant="outline"
                  onClick={() => executeNextPageCall()}
                >
                  {areNextTransactionsLoading ? (
                    <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
                  ) : (
                    t("actions.load_more")
                  )}
                </Button>
              ) : null}
            </>
          ) : (
            <EmptyList>
              <Heading color="shadowBlue" fontSize={[1, 2, 3]}>
                {t("actions.empty")}
              </Heading>

              <Button
                variant="outline"
                mt={[3]}
                onClick={() => history.push(`/${artist.slug}`)}
              >
                {t("actions.cta")}
              </Button>
            </EmptyList>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default Transactions;
