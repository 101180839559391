import React from "react";
import _ from "lodash";
import { Flex, Text } from "rebass";
import { useTranslation, Trans } from "react-i18next";
import useAxios from "axios-hooks";
import useAuth from "../../hooks/auth";
import useArtist from "../../hooks/artist";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import RankingList from "../../components/ranking-list";
import ArtistInfo from "../../components/artist-info";
import Campaigns from "./campaigns";
import Follow from "./follow";
import HallOfFameList from "./hall-of-fame-list";
import DropdownMenu from "./dropdown-menu";
import Prizes from "./prizes";
import WelcomeModal from "../../components/welcome-modal";

const Artist = () => {
  const auth = useAuth();
  const { artist } = useArtist();
  const { t } = useTranslation();
  const { name } = artist;

  const [{ data: rankings, loading: areRankingsLoading }] = useAxios(
    `/api/rankings/${artist.slug}/current`
  );

  const getUserRank = () => _.get(rankings, "data.user_rank", {});

  return (
    <Layout
      coverUrl={artist.cover_picture || artist.picture}
      logo={null}
      historyBackUrl="/"
      title={t("layout.artist_title", { artist: name })}
      description={t("layout.artist_description", { artist: name })}
    >
      <Container>
        <ArtistInfo />

        <Flex mt={[3, 4]}>
          <Follow />

          <DropdownMenu />
        </Flex>
        <Text fontSize={[0, 1]} color="shadowBlue" mt={[3]}>
          <Trans i18nKey="artist.subtitle">
            For every listening to a <strong>{{ name }}</strong> song on Spotify
            you will receive a Mydly point (valid only for the{" "}
            <strong>{{ name }}</strong> ranking). Each ranking is updated every
            30 minutes
          </Trans>
        </Text>

        {areRankingsLoading ? null : (
          <RankingList
            mt={[4, 5]}
            list={[
              {
                name: t("artist.your_position"),
                slug: artist.slug,
                imageSrc: auth.user.picture,
                points: getUserRank() && getUserRank().points,
                pos: getUserRank() && getUserRank().rank
              }
            ]}
          />
        )}
      </Container>

      <Prizes />
      <HallOfFameList artist={artist} />
      <Campaigns artist={artist} />
      <WelcomeModal artist={artist} />
    </Layout>
  );
};

export default Artist;
