export const abbreviateNumber = (value, precision = 1) => {
  let suffix = "";

  if (value < 900000) {
    value = value / 1000;
    suffix = "K";
  } else if (value < 900000000) {
    value = value / 1000000;
    suffix = "M";
  } else if (value < 900000000000) {
    value = value / 1000000000;
    suffix = "B";
  } else {
    value = value / 1000000000000;
    suffix = "T";
  }

  if (Math.round(value) !== value) {
    value = value.toFixed(precision);
  }

  return value + suffix;
};

export const getAcronym = (str = "") => {
  return str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
    .substring(0, 2);
};
