import React from "react";
import { ToastProvider } from "react-toast-notifications";
import { AuthProvider } from "./contexts/auth";
import ThemeProvider from "./contexts/theme";
import Router from "./router";
import config from "./config";

const App = () => {
  return (
    <ThemeProvider>
      <ToastProvider placement="bottom-right">
        <AuthProvider endpoint={config.backend}>
          <Router />
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
