import React from "react";
import { Card } from "rebass";
import PropTypes from "prop-types";

const Dropdown = ({
  isActive = false,
  minWidth = 0,
  maxWidth = "none",
  distance = 8,
  alignment = "left",
  bg = "midnight",
  sx,
  children
}) => {
  return isActive ? (
    <Card
      bg={bg}
      sx={{
        minWidth,
        maxWidth,
        position: "absolute",
        top: `calc(100% + ${distance}px)`,
        left: alignment === "left" ? 0 : "initial",
        right: alignment === "right" ? 0 : "initial",
        ...sx
      }}
    >
      {children}
    </Card>
  ) : null;
};

Dropdown.propTypes = {
  isActive: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ]),
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ]),
  distance: PropTypes.number,
  alignment: PropTypes.string,
  bg: PropTypes.string,
  sx: PropTypes.object
};

export default Dropdown;
