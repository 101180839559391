import React from "react";
import Swiper from "react-id-swiper";
import { Heading, Image, Text, Button } from "rebass";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/auth";
import Slide from "./slide";
import { useToasts } from "react-toast-notifications";
import { redirectToSpotifyAuth } from "../../../actions";
import Slider1 from "../../../images/slider1.jpg";
import Slider2 from "../../../images/slider2.jpg";
import Slider3 from "../../../images/slider3.jpg";
import Slider4 from "../../../images/slider4.jpg";
import _ from "lodash";

const WelcomeSlider = ({ onComplete, artist = {} }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const auth = useAuth();

  const params = {
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };

  return (
    <Swiper {...params}>
      <div>
        <Slide>
          <Image
            src={Slider1}
            sx={{
              width: [300, 250],
              mt: [3, 2, 1]
            }}
          ></Image>

          <Heading
            px={[2, 3, 4]}
            fontSize={[3, 4, 5]}
            mt={[2]}
            color="secondary"
            textAlign="center"
          >
            {t("welcome_slider.slide_1.title")}
          </Heading>

          <Text px={[2, 3, 4]} mt={[2]} textAlign="center" fontSize={[0, 1, 2]}>
            {t("welcome_slider.slide_1.description")}
          </Text>
        </Slide>
      </div>

      <div>
        <Slide>
          <Image
            src={!_.isEmpty(artist) ? artist.picture : Slider2}
            sx={{
              width: [300, 250],
              mt: [3, 2, 1],
              borderRadius: 8
            }}
          ></Image>

          <Heading
            fontSize={[3, 4, 5]}
            mt={[2]}
            px={[2, 3, 4]}
            textAlign="center"
            color="secondary"
          >
            {!_.isEmpty(artist)
              ? t("welcome_slider.slide_2_artist.title", {
                  artist: artist.name
                })
              : t("welcome_slider.slide_2.title")}
          </Heading>

          <Text px={[2, 3, 4]} mt={[2]} textAlign="center" fontSize={[0, 1, 2]}>
            {!_.isEmpty(artist)
              ? t("welcome_slider.slide_2_artist.description", {
                  artist: artist.name
                })
              : t("welcome_slider.slide_2.description")}
          </Text>
        </Slide>
      </div>

      <div>
        <Slide>
          <Image
            src={Slider3}
            sx={{
              width: [300, 250],
              mt: [3, 2, 1]
            }}
          ></Image>

          <Heading
            fontSize={[3, 4, 5]}
            px={[2, 3, 4]}
            mt={[2]}
            textAlign="center"
            color="secondary"
          >
            {t("welcome_slider.slide_3.title")}
          </Heading>

          <Text mt={[2]} px={[2, 3, 4]} textAlign="center" fontSize={[0, 1, 2]}>
            {t("welcome_slider.slide_3.description")}
          </Text>
        </Slide>
      </div>

      <div>
        <Slide>
          <Image
            src={Slider4}
            sx={{
              width: [300, 250]
            }}
          ></Image>

          <Heading
            fontSize={[3, 4, 5]}
            mt={[2]}
            px={[2, 3, 4]}
            textAlign="center"
            color="secondary"
          >
            {t("welcome_slider.slide_4.title")}
          </Heading>

          <Text px={[2, 3, 4]} mt={[2]} textAlign="center" fontSize={[0, 1, 2]}>
            {t("welcome_slider.slide_4.description")}
          </Text>

          {auth.user.spotify_connection ? (
            <Button
              sx={{
                mt: [3],
                bg: "outline",
                px: [0, 1, 2],
                color: "white",
                borderRadius: 20,
                width: ["100%", "50%"]
              }}
              onClick={() => {
                onComplete();
              }}
            >
              {t("welcome_slider.close")}
            </Button>
          ) : (
            <Button
              sx={{
                mt: [3],
                bg: "spotify",
                px: [0, 1, 2],
                color: "white",
                borderRadius: 20,
                width: ["100%", "50%"]
              }}
              onClick={() => {
                redirectToSpotifyAuth({
                  onError: () =>
                    addToast("There was an error. It's not you, it's us.", {
                      appearance: "error",
                      autoDismiss: true
                    })
                });

                onComplete();
              }}
            >
              {t("welcome_slider.connect")}
            </Button>
          )}
        </Slide>
      </div>
    </Swiper>
  );
};

export default WelcomeSlider;
