import React from "react";
import { Box, Card, Flex, Heading, Image, Text } from "rebass";
import PropTypes from "prop-types";
import moment from "moment";

const PrizesList = ({ list, mt = [3, 4] }) => (
  <Box mt={mt}>
    {list.map((el, k) => (
      <Card color="text" p={[3, 4]} sx={{ borderRadius: 8 }} key={k}>
        <Flex alignItems="center">
          <Flex mr={[3]} flexShrink={0}>
            <Image
              src={el.picture}
              variant="avatar"
              sx={{
                width: [40, 50],
                height: [40, 50]
              }}
            />
          </Flex>

          <Flex pr={[2, 3]} flexGrow={1} flexDirection="column">
            <Heading
              fontSize={[1, 2, 3]}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {el.title}
            </Heading>

            <Text
              mt={[1]}
              color="primary"
              sx={{
                fontWeight: 300,
                textTransform: "uppercase"
              }}
              fontSize={[0, 1]}
            >
              {el.artist.name}
            </Text>
          </Flex>

          <Flex maxWidth={[60, 70]} flexShrink={0}>
            <Text
              color="shadowBlue"
              sx={{
                fontWeight: 300,
                textTransform: "uppercase"
              }}
              fontSize={[0, 1]}
            >
              {moment.utc(el.end_date).format("L")}
            </Text>
          </Flex>
        </Flex>
      </Card>
    ))}
  </Box>
);

PrizesList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      picture: PropTypes.string,
      artist: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired,
      end_date: PropTypes.date
    })
  ).isRequired
};

export default PrizesList;
