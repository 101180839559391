import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from "react";
import axios from "axios";
import { Card } from "rebass";
import Loader from "../../components/_layout/loader";

const ArtistContext = createContext();

const ArtistProvider = ({ children, routeContext, slug }) => {
  const initialState = {
    isLoading: true,
    isErrored: false,
    artist: null
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "IS_LOADING_UPDATE":
        return {
          ...state,
          isLoading: action.payload
        };

      case "IS_ERRORED_UPDATE":
        return {
          ...state,
          isErrored: action.payload
        };

      case "ARTIST_DATA_UPDATE":
        return {
          ...state,
          artist: action.payload
        };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleError = () => {
    dispatch({
      type: "IS_ERRORED_UPDATE",
      payload: true
    });

    dispatch({
      type: "IS_LOADING_UPDATE",
      payload: false
    });
  };

  const getArtistData = useCallback(async () => {
    try {
      const response = await axios.get(`/api/artists/${slug}`);

      dispatch({
        type: "ARTIST_DATA_UPDATE",
        payload: response.data.data.artist
      });
    } catch (e) {
      handleError();
    }
  }, [slug]);

  const getData = useCallback(async () => {
    try {
      await getArtistData();

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false
      });
    } catch (e) {}
  }, [getArtistData]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (state.isErrored) {
    return (
      <Card bg="danger" p={3}>
        There was an error :(
      </Card>
    );
  }

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <ArtistContext.Provider
      value={{
        ...state,
        dispatch,
        getArtistData
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
};

export { ArtistProvider, ArtistContext };
