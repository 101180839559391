import React, { useEffect } from "react";
import { Box, Heading } from "rebass";
import _ from "lodash";
import useAxios from "axios-hooks";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import RankingList from "../../../components/ranking-list";

const Rankings = ({ mt }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [{ data: rankings, loading: isLoading, error: isErrored }] = useAxios(
    `/api/rankings`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  const getRankings = () => _.get(rankings, "data.rankings.data", []);

  return !isLoading && getRankings().length ? (
    <Box mt={mt}>
      <Heading fontSize={[3, 4]}>{t("home.your_rankings")}</Heading>

      <RankingList
        list={_.map(getRankings(), ranking => ({
          name: ranking.artist.name,
          slug: ranking.artist.slug,
          imageSrc: ranking.artist.picture,
          points: ranking.points,
          pos: ranking.rank
        }))}
        mt={[3]}
      />
    </Box>
  ) : null;
};

export default Rankings;
