require("dotenv").config({
  path: ".env"
});

export default {
  backend: process.env.REACT_APP_BACKEND_URL,
  url: process.env.REACT_APP_URL,
  landing: process.env.REACT_APP_LANDING_URL,
  fb_app_id: process.env.REACT_APP_FACEBOOK_APP_ID
};
