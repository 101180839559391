import React from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Slider = ({
  items,
  spaceBetween = 8,
  slidesPerView = window.innerWidth < 640 ? 2.2 : 4.3,
  append = <></>
}) => {
  const swiperParams = {
    spaceBetween,
    slidesPerView
  };

  return (
    <Swiper {...swiperParams}>
      {items}

      {append}
    </Swiper>
  );
};

Slider.propTypes = {
  items: PropTypes.any.isRequired,
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  append: PropTypes.element
};

export default Slider;
