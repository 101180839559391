import React from "react";
import { Box, Card, Flex, Heading, Text, Image } from "rebass";
import PointsImage from "../../images/points.svg";
import PropTypes from "prop-types";

const TransactionList = ({ list }) => {
  return (
    <Box mt={[3, 4]}>
      {list.map((el, k) => (
        <Card
          key={k}
          color="text"
          mt={k !== 0 && [2, 3]}
          p={[3, 4]}
          sx={{ borderRadius: 8 }}
        >
          <Flex alignItems="center" justifyContent="between">
            <Flex mr={[3, 4]} flexShrink={0}>
              <Image src={el.img} width={[20, 30, 40]} height={[20, 30, 40]} />
            </Flex>

            <Flex flexGrow={1} pr={[2, 3]} flexDirection="column">
              <Heading
                fontSize={[1, 2, 3]}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {el.name}
              </Heading>

              <Text
                mt={[1]}
                color="shadowBlue"
                sx={{
                  fontWeight: 300,
                  fontStyle: "italic"
                }}
                fontSize={[0, 1]}
              >
                {el.date}
              </Text>
            </Flex>

            <Flex flexShrink={0} ml={[2]}>
              <Flex justifyContent="center" alignItems="center">
                <Heading fontSize={[1, 2, 3]} mr={[1, 2]}>
                  {el.points}
                </Heading>

                <Image src={PointsImage} sx={{ width: 20 }} />
              </Flex>
            </Flex>
          </Flex>
        </Card>
      ))}
    </Box>
  );
};

TransactionList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired
    })
  ).isRequired
};

export default TransactionList;
