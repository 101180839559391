import React, { useState } from "react";
import { Box, Button, Card, Flex, Image, Heading, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import Container from "../../components/_layout/container";
import useAuth from "../../hooks/auth";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo-light.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const RequestPasswordReset = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm();

  const onSubmit = async data => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await auth.resetPasswordRequest({
        data
      });

      setIsSubmitted(true);
    } catch (e) {
      setIsLoading(false);

      setError("server", "error", "Server error, please retry.");
    }

    setIsLoading(false);
  };

  return (
    <Container maxWidth={400}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Flex flexDirection="column" alignItems="center" mb={[4]} p={[2]}>
          <Image
            src={logo}
            sx={{
              width: ["130px"]
            }}
            mb={[3]}
          />
          <Heading textAlign="center">{t("reset-password.title")}</Heading>
          <Text textAlign="center" fontSize={[0, 1, 2]} mt={[2]}>
            {t("reset-password.subtitle")}
          </Text>
        </Flex>

        {errors.server && (
          <Card
            width="100%"
            data-testid={`server-${errors.server.type}`}
            bg="danger"
            color="white"
            sx={{
              borderRadius: 8,
              textAlign: "center"
            }}
            mb={3}
            p={3}
          >
            {errors.server.message}
          </Card>
        )}

        <Card
          width="100%"
          p={4}
          sx={{
            borderRadius: 8,
            boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
          }}
        >
          {isSubmitted ? (
            <>
              <Heading textAlign="center">
                {t("reset-password.success_title")}
              </Heading>
              <Text fontSize={[0, 1, 2]} textAlign="center">
                {t("reset-password.success_subtitle")}
              </Text>
            </>
          ) : (
            <form
              data-testid={`auth-${
                auth.isAuthenticated ? "isLogged" : "isNotLogged"
              }`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box>
                <Label
                  htmlFor="email"
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: "shadowBlue"
                  }}
                  fontSize={[0]}
                >
                  {t("register.label.email")}
                </Label>
                <Input
                  data-testid="email"
                  name="email"
                  type="email"
                  bg="background"
                  sx={{
                    border: "none",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                    color: "shadowBlue"
                  }}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address"
                    }
                  })}
                />
                {errors.email && errors.email.type === "pattern" && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="invalid-email-pattern"
                  >
                    {t("register.errors.invalid_email")}
                  </Text>
                )}
                {errors.email && errors.email.type === "required" && (
                  <Text
                    fontSize={[0, 1]}
                    color="danger"
                    data-testid="invalid-email-required"
                  >
                    {t("register.errors.missing_email")}
                  </Text>
                )}
              </Box>

              <Box mt={3}>
                <Button
                  disabled={isLoading}
                  data-testid="submit"
                  width="100%"
                  mt={[1]}
                  type="submit"
                  p={[3]}
                  variant="shadowBlue"
                  fontSize={[0, 1]}
                  sx={{
                    fontWeight: 800,
                    textTransform: "uppercase",
                    boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                  }}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon={faCircleNotch}></FontAwesomeIcon>
                  ) : (
                    t("reset-password.submit")
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Card>
      </Flex>
    </Container>
  );
};

export default RequestPasswordReset;
