import React from "react";
import Layout from "../../components/_layout";
import Container from "../../components/_layout/container";
import ConnectToSpotify from "./connect-to-spotify";
import Rankings from "./rankings";
import ArtistsCarousel from "./artists-carousel";
import FaqSection from "./faq-section";
import Prizes from "./prizes";
import WelcomeModal from "../../components/welcome-modal";

const Home = () => {
  return (
    <Layout>
      <ArtistsCarousel />

      <Container>
        <ConnectToSpotify mt={[4]} />
        <FaqSection mt={[4]} />
        <Rankings mt={[4]} />
        <Prizes mt={[4]} />
      </Container>

      <WelcomeModal />
    </Layout>
  );
};

export default Home;
