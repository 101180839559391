import React from "react";
import { Flex } from "rebass";

const Slide = ({ children }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{
        mx: "auto",
        py: [1, 2, 3],
        px: [2, 3, 4],
        height: ["90vh", "85vh"],
        color: "black",
        backgroundColor: "white",
        borderRadius: 8
      }}
    >
      {children}
    </Flex>
  );
};

export default Slide;
