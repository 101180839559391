import React, { useState } from "react";
import useAxios from "axios-hooks";
import { Button, Flex, Heading, Text } from "rebass";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { redirectToSpotifyAuth } from "../../../actions";
import useAuth from "../../../hooks/auth";
import useArtist from "../../../hooks/artist";
import useModal from "../../../hooks/modal";
import Modal from "../../../components/_layout/modal";

const Follow = () => {
  const auth = useAuth();
  const { addToast } = useToasts();
  const { artist, getArtistData } = useArtist();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen, toggle] = useModal();

  const [isFollowing, setIsFollowing] = useState(artist.follow_status);

  const [{ loading: followLoading }, executeFollow] = useAxios(
    {
      url: `/api/artists/follow/${artist.slug}`,
      method: "POST"
    },
    { manual: true }
  );

  const [{ loading: unfollowLoading }, executeUnfollow] = useAxios(
    {
      url: `/api/artists/unfollow/${artist.slug}`,
      method: "POST"
    },
    { manual: true }
  );

  const handleError = () => {
    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  };

  const handleFollow = async () => {
    if (!auth.user.spotify_connection) {
      return setIsModalOpen(true);
    }

    try {
      const response = await executeFollow();

      if (response.data.success) {
        setIsFollowing(true);
        getArtistData();

        addToast(t("artist.follow_confirmation", { artistName: artist.name }), {
          appearance: "success",
          autoDismiss: true
        });
      } else {
        handleError();
      }
    } catch (e) {
      handleError();
    }
  };

  const handleUnfollow = async () => {
    try {
      const response = await executeUnfollow();

      if (response.data.success) {
        setIsFollowing(false);
      } else {
        handleError();
      }
    } catch (e) {
      handleError();
    }
  };

  if (followLoading || unfollowLoading) {
    return (
      <Button
        disabled={true}
        bg="secondary"
        width="100%"
        flexGrow={1}
        sx={{
          py: [2, 3],
          px: [1, 2]
        }}
      >
        <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
      </Button>
    );
  }

  if (isFollowing) {
    return (
      <Button
        onClick={() => handleUnfollow()}
        bg="midnight"
        color="shadowBlue"
        width="100%"
        flexGrow={1}
        sx={{
          py: [2, 3],
          px: [1, 2]
        }}
      >
        {t("artist.unfollow")}
      </Button>
    );
  }

  return (
    <>
      <Button
        onClick={() => handleFollow()}
        width="100%"
        flexGrow={1}
        sx={{
          py: [2, 3],
          px: [1, 2]
        }}
      >
        {t("artist.follow")}
      </Button>

      <Modal
        isActive={isModalOpen}
        toggle={toggle}
        minWidth={["90vw", "75vw", "50vw"]}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading fontSize={[3, 4, 5]} color="text">
            {t("artist.modal.follow", { artistName: artist.name })}
          </Heading>

          <Text fontSize={[1, 2, 3]} mt={[2]} px={[2, 3, 4]} textAlign="center">
            {t("artist.modal.desc", { artistName: artist.name })}
          </Text>

          <Button
            sx={{
              mt: [3],
              bg: "spotify",
              px: [0, 1, 2],
              color: "white",
              borderRadius: 20,
              width: ["100%", "50%"]
            }}
            onClick={() => {
              redirectToSpotifyAuth({
                onError: () =>
                  addToast("There was an error. It's not you, it's us.", {
                    appearance: "error",
                    autoDismiss: true
                  })
              });
            }}
          >
            {t("artist.modal.connect")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Follow;
