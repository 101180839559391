import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading, Card, Flex, Box, Text } from "rebass";

const FaqSection = ({ mt }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Heading mt={mt} fontSize={[3, 4]}>
        {t("home.faq_header")}
      </Heading>

      <Card mt={[3, 4]} variant="ctaCard" onClick={() => history.push("/faq")}>
        <Flex>
          <Box flexGrow={1}>
            <Text variant="ctaCard.topTitle">{t("home.faq_title")}</Text>

            <Heading variant="ctaCard.title">{t("home.faq_subtitle")}</Heading>
          </Box>
        </Flex>

        <Text variant="ctaCard.description">{t("home.faq_description")}</Text>
      </Card>
    </>
  );
};

export default FaqSection;
