import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Card, Flex, Heading, Text, Image, Link } from "rebass";
import { Label, Input, Checkbox } from "@rebass/forms";
import { useForm } from "react-hook-form";
import Container from "../../components/_layout/container";
import useAuth from "../../hooks/auth";
import axios from "axios";
import _ from "lodash";
import logo from "../../images/logo-light.svg";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import cookiePolicy from "../cookie-policy/Informativa_sui_Cookie_29-06.2020.pdf";
import privacyPolicy from "../privacy-policy/Privacy_policy.pdf";

const Register = ({ history }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { register, watch, handleSubmit, setError, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [invitationInfo, setInvitationInfo] = useState();

  const invitationUser = window.sessionStorage.getItem("invitation_user");
  const invitationArtist = window.sessionStorage.getItem("invitation_artist");

  const onSubmit = async data => {
    if (isLoading) {
      return;
    }

    if (invitationArtist && invitationUser) {
      data = {
        ...data,
        ref_user: invitationUser,
        ref_artist: invitationArtist
      };
    }

    setIsLoading(true);

    try {
      await auth.register({
        data,
        onSuccess: () => {
          setIsLoading(false);

          if (!_.isEmpty(invitationInfo)) {
            history.push(`/${invitationInfo.artist.slug}`);
          } else {
            history.push("/");
          }
        }
      });
    } catch (e) {
      setIsLoading(false);

      if (e.status === 422) {
        setError(
          "server",
          "accountAlreadyExisting",
          "Server error, please retry."
        );
      } else {
        setError("server", "error", "Server error, please retry.");
      }
    }

    setIsLoading(false);
  };

  const fetchInvitationInfo = async (artist, hashid) => {
    try {
      const invitationArtistResponse = await axios.get(
        `/api/artists/${artist}/basic`
      );
      const invitationUserResponse = await axios.get(
        `/api/users/hashid/${hashid}`
      );

      const info = {
        artist: invitationArtistResponse.data.data.artist,
        user: invitationUserResponse.data.data.user
      };

      setInvitationInfo(info);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (invitationArtist && invitationUser) {
      fetchInvitationInfo(invitationArtist, invitationUser);
    }
  }, [invitationArtist, invitationUser]);

  return (
    <Container maxWidth={400}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Flex flexDirection="column" alignItems="center" mb={[4]}>
          <Image
            src={logo}
            sx={{
              width: ["130px"]
            }}
            mb={[3]}
          />
          <Heading>{t("register.title")}</Heading>
          <Text fontSize={[0, 1, 2]} mt={[2]}>
            {t("register.subtitle")}
            <Link href="/login" ml={[1]}>
              Log in
            </Link>
          </Text>
        </Flex>

        {errors.server && (
          <Card
            data-testid={`server-${errors.server.type}`}
            bg="danger"
            color="white"
            mb={3}
            p={3}
            width={["100%"]}
            sx={{
              textAlign: "center"
            }}
          >
            {errors.server.message}
          </Card>
        )}

        <Card
          width="100%"
          p={4}
          sx={{
            borderRadius: 8,
            boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
          }}
        >
          <form
            id="signup-form"
            data-testid={`auth-${
              auth.isAuthenticated ? "isLogged" : "isNotLogged"
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box>
              <Label
                htmlFor="name"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "shadowBlue"
                }}
                fontSize={[0]}
              >
                {t("register.label.name")}
              </Label>

              <Input
                mt={[1]}
                data-testid="name"
                name="name"
                type="text"
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)",
                  color: "shadowBlue"
                }}
                ref={register({
                  required: true,
                  minLength: 3
                })}
              />

              {errors.name && errors.name.type === "required" && (
                <Text
                  data-testid="name-missing"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.missing_name")}
                </Text>
              )}

              {errors.name && errors.name.type === "minLength" && (
                <Text
                  data-testid="name-min-length"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.short_name")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Label
                htmlFor="email"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "shadowBlue"
                }}
                fontSize={[0]}
              >
                {t("register.label.email")}
              </Label>

              <Input
                mt={[1]}
                data-testid="email"
                name="email"
                type="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address"
                  }
                })}
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                }}
              />

              {errors.email && errors.email.type === "pattern" && (
                <Text
                  data-testid="invalid-email-pattern"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.invalid_email")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Label
                htmlFor="password"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "shadowBlue"
                }}
                fontSize={[0]}
              >
                {t("register.label.password")}
              </Label>

              <Input
                mt={[1]}
                data-testid="password"
                name="password"
                type="password"
                ref={register({
                  required: true,
                  minLength: 8
                })}
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                }}
              />

              {errors.password && errors.password.type === "required" && (
                <Text
                  data-testid="password-missing"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.missing_password")}
                </Text>
              )}

              {errors.password && errors.password.type === "minLength" && (
                <Text
                  data-testid="password-min-length"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.short_password")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Label
                htmlFor="password_confirmation"
                sx={{
                  fontWeight: 600,
                  color: "shadowBlue",
                  textTransform: "uppercase"
                }}
                fontSize={[0]}
              >
                {t("register.label.confirm_password")}
              </Label>

              <Input
                mt={[1]}
                bg="background"
                sx={{
                  border: "none",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                }}
                data-testid="password_confirmation"
                name="password_confirmation"
                type="password"
                ref={register({
                  validate: value => value === watch("password")
                })}
              />

              {errors.password_confirmation && (
                <Text
                  data-testid="passwords-dont-match"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.unmatched_password")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Label
                htmlFor="terms"
                sx={{
                  fontWeight: 600,
                  color: "shadowBlue",
                  textTransform: "uppercase",
                  alignItems: "center"
                }}
                fontSize={[0]}
              >
                <Checkbox
                  id="terms"
                  name="terms"
                  ref={register({
                    required: true
                  })}
                />
                <div>
                  Accetto la{" "}
                  <Link href={cookiePolicy} target="_blank">
                    cookie policy
                  </Link>{" "}
                  e{" "}
                  <Link href={privacyPolicy} target="_blank">
                    privacy policy
                  </Link>
                </div>
              </Label>

              {errors.terms && (
                <Text
                  data-testid="terms-missing"
                  color="danger"
                  fontSize={[0, 1, 2]}
                >
                  {t("register.errors.missing_terms")}
                </Text>
              )}
            </Box>

            <Box mt={3}>
              <Button
                disabled={isLoading}
                data-testid="submit"
                width="100%"
                type="submit"
                p={[3]}
                variant="shadowBlue"
                fontSize={[0]}
                sx={{
                  fontWeight: 800,
                  textTransform: "uppercase",
                  boxShadow: "0 3px 6px 0 rgba(0,0,0,.05)"
                }}
              >
                {isLoading ? (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    size="1x"
                    spin
                  ></FontAwesomeIcon>
                ) : (
                  t("register.submit")
                )}
              </Button>
            </Box>
          </form>
        </Card>
      </Flex>
    </Container>
  );
};

export default withRouter(Register);
