import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Flex, Heading, Text } from "rebass";
import { useToasts } from "react-toast-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { redirectToSpotifyAuth } from "../../../actions";
import useAuth from "../../../hooks/auth";

const ConnectToSpotify = ({ mt, p = [3, 4, 5] }) => {
  const auth = useAuth();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");

  return auth.user.spotify_connection || code ? null : (
    <>
      <Heading mt={mt} fontSize={[3, 4]}>
        {t("home.your_profiles")}
      </Heading>

      <Card
        mt={[3, 4]}
        variant="ctaCard"
        onClick={() =>
          redirectToSpotifyAuth({
            onError: () =>
              addToast("There was an error. It's not you, it's us.", {
                appearance: "error",
                autoDismiss: true
              })
          })
        }
      >
        <Flex>
          <Box flexGrow={1}>
            <Text variant="ctaCard.topTitle">
              {t("connect_to_spotify.start_here")}
            </Text>

            <Heading variant="ctaCard.title">
              {t("connect_to_spotify.title")}
            </Heading>
          </Box>

          <Box variant="ctaCard.iconContainer" color="spotify">
            <FontAwesomeIcon icon={faSpotify} size="2x" />
          </Box>
        </Flex>

        <Text variant="ctaCard.description">
          {t("connect_to_spotify.description")}
        </Text>
      </Card>
    </>
  );
};

export default ConnectToSpotify;
