import React from "react";
import Modal from "../_layout/modal";
import WelcomeSlider from "./welcome-slider";
import useModal from "../../hooks/modal";

const WelcomeModal = ({ artist = {} }) => {
  const [isModalOpen, setIsModalOpen, toggle] = useModal();

  React.useEffect(() => {
    setIsModalOpen(!localStorage.getItem("welcome_slider_complete"));
  }, [setIsModalOpen]);

  const setWelcomeComplete = () => {
    toggle({
      onClose: () => {
        localStorage.setItem("welcome_slider_complete", true);
      }
    });
  };

  return (
    <Modal
      isActive={isModalOpen}
      toggle={setWelcomeComplete}
      maxWidth={["90vw", "75vw", "50vw"]}
    >
      <WelcomeSlider artist={artist} onComplete={setWelcomeComplete} />
    </Modal>
  );
};

export default WelcomeModal;
