import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";
import "./i18n";
import TagManager from "react-gtm-module";
import moment from "moment-timezone";

moment.tz.setDefault(moment.tz.guess());

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_ID
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN
});

ReactDOM.render(<App />, document.getElementById("root"));
