import React from "react";
import { Card, Flex } from "rebass";

const EmptyList = ({ px = [3, 4, 5], py = [4, 5], children }) => {
  return (
    <Card
      width="100%"
      px={px}
      py={py}
      mt={4}
      sx={{
        borderRadius: 8,
        boxShadow: "0 2px 7px 0 hsla(0, 0%, 0%, 0.2)"
      }}
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        {children}
      </Flex>
    </Card>
  );
};

export default EmptyList;
