import React from "react";
import { ThemeProvider } from "theme-ui";
import preset from "@rebass/preset";
import dark from "@theme-ui/preset-dark";

export default props => {
  let theme = {
    ...preset,
    ...dark
  };

  theme.colors = {
    ...theme.colors,
    background: "#0f111c",
    primary: "#5d93fd",
    secondary: "#141623",
    midnight: "#272934",
    shadowBlue: "#7a859c",
    danger: "#e00000",
    whatsapp: "#25d366",
    facebook: "#4267b2",
    messenger: "#006aff",
    spotify: "#1db954",
    gold: "#fcb941",
    silver: "#bdc3c7",
    bronze: "#bf7731"
  };

  theme.radii = {
    default: 8,
    circle: 99999
  };

  theme.variants.card = {
    ...theme.variants.card,
    bg: "secondary",
    color: "shadowBlue",
    borderRadius: "default"
  };

  theme.variants.ctaCard = {
    ...theme.variants.card,
    p: ["20px", "48px"],
    cursor: "pointer",
    iconContainer: {
      fontSize: [2, 3],
      mt: 2,
      "> img": {
        width: "32px"
      }
    }
  };

  theme.text.ctaCard = {
    topTitle: {
      ...theme.text.heading,
      color: "primary",
      fontWeight: 300,
      fontSize: [0, 1]
    },
    title: {
      mt: [2, 3],
      fontSize: [2, 3],
      color: "text"
    },
    description: {
      mt: ["8px", "12px"],
      fontSize: [2, 3],
      fontWeight: "300"
    }
  };

  theme.variants.topCover = {
    maxWidth: "100%",
    overflow: "hidden",
    menuContainer: {
      alignSelf: "flex-start",
      width: "100%",
      justifyContent: "space-between",
      position: "absolute",
      p: 3,
      zIndex: 1
    },
    backgroundContainer: {
      height: [250, 400],
      alignItems: "flex-end",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      filter: "blur(4px)",
      transform: "scale(1.1)",
      transformOrigin: "bottom"
    },
    gradientContainer: {
      height: [250, 400],
      width: "100%",
      background: `linear-gradient(transparent 0%, ${theme.colors.background} 90%, ${theme.colors.background} 100%)`
    }
  };

  theme.forms = {
    input: {
      "&:focus": {
        outline: "none",
        boxShadow: "none"
      }
    }
  };

  theme.variants.sliderItem = {
    position: "relative",
    top: 0,
    link: {
      ...theme.variants.nav,
      p: 0,
      display: "block"
    },
    imageContainer: {
      alignItems: "flex-end",
      width: ["100%"],
      height: ["65vw", "50vh"],
      backgroundSize: "cover",
      borderRadius: 8
    },
    gradientContainer: {
      p: [3],
      pt: [5],
      borderRadius: 8,
      flexGrow: 1,
      background: "linear-gradient(transparent, rgba(0,0,0,0.9))",
      "> h2": {
        fontSize: [2, 3]
      },
      "> div, > div span": {
        color: "shadowBlue",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    }
  };

  const defaultButtonStyle = {
    borderRadius: "circle",
    fontWeight: 400,
    cursor: "pointer",
    outline: "none",
    "&:focus": {
      outline: "none",
      boxShadow: "none"
    }
  };

  theme.buttons = {
    ...theme.buttons,
    "&:focus": {
      outline: "none",
      boxShadow: "none"
    },
    primary: {
      ...theme.buttons.primary,
      ...defaultButtonStyle,
      color: "text"
    },
    outline: {
      ...theme.buttons.outline,
      ...defaultButtonStyle
    },
    secondary: {
      ...theme.buttons.secondary,
      ...defaultButtonStyle
    }
  };

  console.log(theme);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
