import React from "react";
import { Flex } from "rebass";
import RingLoader from "react-spinners/RingLoader";

const Loader = () => (
  <Flex height="100vh" alignItems="center" justifyContent="center">
    <RingLoader size={50} color={"#fff"} loading />
  </Flex>
);

export default Loader;
