import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import useAxios from "axios-hooks";
import PrizesList from "../../../components/prizes-list";
import _ from "lodash";
import { Box, Heading, Button } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Prizes = ({ mt }) => {
  const { t } = useTranslation();
  const [prizes, setPrizes] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  const [{ data: prizesData, loading: arePrizesLoading }] = useAxios(
    `/api/prizes`
  );

  const getPrizes = useCallback(
    () => _.get(prizesData, "data.prizes.data", {}),
    [prizesData]
  );

  const [
    { data: nextPagePrizesData, loading: areNextPrizesLoading },
    executeNextPageCall
  ] = useAxios(nextPageUrl, { manual: true });

  const getNextPagePrizes = useCallback(
    () => _.get(nextPagePrizesData, "data.prizes", {}),
    [nextPagePrizesData]
  );

  useEffect(() => {
    if (!prizesData) {
      return;
    }

    setPrizes(getPrizes());
    setNextPageUrl(getPrizes().next_page_url);
  }, [prizesData, getPrizes]);

  useEffect(() => {
    if (!nextPagePrizesData) {
      return;
    }

    setPrizes(txs => txs.concat(getNextPagePrizes().data));
    setNextPageUrl(getNextPagePrizes().next_page_url);
  }, [nextPagePrizesData, getNextPagePrizes]);

  return !arePrizesLoading && getPrizes().length ? (
    <Box mt={mt}>
      <Heading fontSize={[3, 4]}>{t("home.your_prizes")}</Heading>

      <PrizesList list={prizes} mt={[3]} />

      {nextPageUrl ? (
        <Button
          disabled={areNextPrizesLoading}
          mt={[3, 4]}
          py={[2, 3]}
          px={[1, 2]}
          width="100%"
          variant="outline"
          onClick={() => executeNextPageCall()}
        >
          {areNextPrizesLoading ? (
            <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
          ) : (
            t("actions.load_more")
          )}
        </Button>
      ) : null}
    </Box>
  ) : null;
};

export default Prizes;
