import React, { useEffect } from "react";
import { Box, Heading, Image, Flex, Text } from "rebass";
import moment from "moment";
import useAxios from "axios-hooks";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";
import Slider from "../../../components/_layout/slider";
import Container from "../../../components/_layout/container";
import PointsImage from "../../../images/points.svg";

const HallOfFameList = ({ artist }) => {
  const { addToast } = useToasts();

  const [{ data: rankings, loading: isLoading, error: isErrored }] = useAxios(
    `/api/rankings/${artist.slug}/summary`
  );

  useEffect(() => {
    if (!isErrored) {
      return;
    }

    addToast("There was an error. It's not you, it's us.", {
      appearance: "error",
      autoDismiss: true
    });
  }, [addToast, isErrored]);

  const getHallOfFame = () => _.get(rankings, "data.rankings", []);

  const items = () =>
    _.filter(getHallOfFame(), month => month.rank).map((el, k) => (
      <Box key={k} data-testid="slider-item" variant="sliderItem">
        <Flex
          sx={{
            backgroundImage: `url(${el.rank.user.picture})`
          }}
          variant="sliderItem.imageContainer"
        >
          <Box variant="sliderItem.gradientContainer">
            <Heading>{el.rank.user.name}</Heading>
            <Text fontSize={[0]} display="flex" alignItems="center">
              <Image
                flexShrink={0}
                src={PointsImage}
                mr={[1]}
                sx={{ width: 13 }}
              />

              <span>
                {el.rank.points} -{" "}
                {moment.utc(el.start_date).format("MMMM YYYY")}
              </span>
            </Text>
          </Box>
        </Flex>
      </Box>
    ));

  return !isLoading &&
    getHallOfFame() &&
    getHallOfFame().length &&
    _.find(getHallOfFame(), month => month.rank) ? (
    <Container px={null} pr={null}>
      <Box mt={[4, 5]} mb={[3]}>
        <Heading fontSize={[3, 4]}>Hall of Fame</Heading>
      </Box>

      <Box mt={[3, 4]}>
        <Slider items={items()} append={<Box width={20} />} />
      </Box>
    </Container>
  ) : null;
};

export default HallOfFameList;
