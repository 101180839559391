import React from "react";
import { Box, Heading, Image, Flex, Text } from "rebass";
import { useTranslation } from "react-i18next";
import useArtist from "../../hooks/artist";
import { abbreviateNumber } from "../../utils";

const ArtistInfo = () => {
  const { artist } = useArtist();
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" mt={-5} sx={{ position: "relative", zIndex: 1 }}>
      <Box flexShrink={0}>
        <Image
          src={artist.picture}
          sx={{
            width: [75],
            height: [75],
            borderRadius: ["50%"]
          }}
        />
      </Box>

      <Box ml={[3]}>
        <Heading fontSize={[3]}>{artist.name}</Heading>
        <Text color="shadowBlue">
          {t("artist.followers", {
            count: abbreviateNumber(artist.followers)
          })}
        </Text>
      </Box>
    </Flex>
  );
};

export default ArtistInfo;
