import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import config from "../../config";
import Loader from "../../components/_layout/loader";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const hasJWT = () => {
    return (
      !!localStorage.getItem("jwt") &&
      !!localStorage.getItem("jwt_expiration_date")
    );
  };

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(`/api/auth/me`);

      dispatch({
        type: "USER_DATA_UPDATE",
        payload: response.data.data.user
      });

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false
      });

      return response.data.data.user;
    } catch (e) {
      throw e;
    }
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.post(`/api/auth/refresh`);

      localStorage.setItem("jwt", response.data.access_token);
      localStorage.setItem(
        "jwt_expiration_date",
        moment()
          .add(response.data.expires_in || 3600, "seconds")
          .format()
      );

      dispatch({
        type: "IS_AUTHENTICATED_UPDATE",
        payload: true
      });

      return;
    } catch (e) {
      throw e;
    }
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("jwt_expiration_date");

    dispatch({
      type: "IS_AUTHENTICATED_UPDATE",
      payload: false
    });

    dispatch({
      type: "USER_DATA_UPDATE",
      payload: null
    });
  };

  const login = async ({ credentials, onSuccess = () => {} }) => {
    try {
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwt_expiration_date");

      const response = await axios(`/api/auth/login`, {
        method: "post",
        data: credentials
      });

      localStorage.setItem("jwt", response.data.access_token);
      localStorage.setItem(
        "jwt_expiration_date",
        moment()
          .add(response.data.expires_in || 3600, "seconds")
          .format()
      );

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: true
      });

      dispatch({
        type: "IS_AUTHENTICATED_UPDATE",
        payload: true
      });

      onSuccess();

      return response;
    } catch (e) {
      throw e.response;
    }
  };

  const register = async ({ data, onSuccess = () => {} }) => {
    try {
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwt_expiration_date");

      const response = await axios(`/api/auth/register`, {
        method: "post",
        data
      });

      localStorage.setItem("jwt", response.data.access_token);
      localStorage.setItem(
        "jwt_expiration_date",
        moment()
          .add(response.data.expires_in || 3600, "seconds")
          .format()
      );

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: true
      });

      dispatch({
        type: "IS_AUTHENTICATED_UPDATE",
        payload: true
      });

      onSuccess();

      return response;
    } catch (e) {
      throw e.response;
    }
  };

  const resetPasswordRequest = async ({ data }) => {
    try {
      const response = await axios(`/api/auth/password/email`, {
        method: "post",
        data
      });

      return response;
    } catch (e) {
      throw e.response;
    }
  };

  const resetPassword = async ({ data }) => {
    try {
      const response = await axios(
        `/api/auth/password/reset?token=${data.code}`,
        {
          method: "post",
          data
        }
      );

      return response;
    } catch (e) {
      throw e.response;
    }
  };

  const initialState = {
    isLoading: true,
    isAuthenticated: hasJWT(),
    user: null
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "IS_LOADING_UPDATE":
        return {
          ...state,
          isLoading: action.payload
        };

      case "IS_AUTHENTICATED_UPDATE":
        return {
          ...state,
          isAuthenticated: action.payload
        };

      case "USER_DATA_UPDATE":
        return {
          ...state,
          user: action.payload
        };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.isAuthenticated) {
      return;
    }

    const saveSpotifyInfo = async ({ code }) => {
      try {
        await axios.post("/api/auth/connect/spotify/callback", {
          code
        });

        await fetchUserInfo();

        addToast(t("connect_to_spotify.success"), {
          appearance: "success",
          autoDismiss: true
        });

        window.history.pushState({}, document.title, window.location.pathname);
      } catch (e) {
        const message =
          _.get(e, "response.data.error") === "CONNECTION_EXISTS"
            ? t("connect_to_spotify.error_exists")
            : "There was an error. It's not you, it's us.";

        addToast(message, {
          appearance: "error",
          autoDismiss: true
        });
      }
    };

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get("code");

    if (!code) {
      return;
    }

    try {
      saveSpotifyInfo({ code });
    } catch (e) {}
  }, [t, addToast, fetchUserInfo, state.isAuthenticated]);

  useEffect(() => {
    if (!state.isAuthenticated) {
      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false
      });

      return;
    }

    fetchUserInfo();
  }, [fetchUserInfo, state.isAuthenticated]);

  axios.defaults.baseURL = config.backend;

  axios.interceptors.request.use(config => {
    const jwt = localStorage.getItem("jwt");

    config.headers.common["Content-Type"] = "application/json";
    config.headers.common["Accept"] = "application/json";

    if (jwt) {
      config.headers.common["Authorization"] = `Bearer ${jwt}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      try {
        await refreshToken();

        const jwt = localStorage.getItem("jwt");

        originalRequest.headers["Authorization"] = `Bearer ${jwt}`;

        return axios(originalRequest);
      } catch (e) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("jwt_expiration_date");

        window.location.href = "/login";
      }
    }
  );

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        fetchUserInfo,
        login,
        logout,
        register,
        resetPasswordRequest,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
